<template>
  <section id="process">
    <TopBanner
      v-if="nowPageData"
      :mainBg="nowPageData.mainBg"
      :pageName="nowPageData.pageName"
      :titleWord="nowPageData.titleWord"
      :bottomBgColor="nowPageData.bottomBgColor"/>

    <router-view/>

    <SiblingNavbox
      :siblingData="siblingData"/>
  </section>
</template>

<script>
import { processPageData } from '@/utils/processPageData';
import TopBanner from '@/components/public/TopBanner.vue';
import SiblingNavbox from '@/components/public/SiblingNavbox.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'Process',
  mixins: [titleMixin],
  components: {
    TopBanner,
    SiblingNavbox,
  },
  data() {
    return {
      processPageData,
    };
  },
  computed: {
    nowPageData() {
      return processPageData.find(
        (item) => item.routerName === this.$route.name,
      );
    },
    siblingData() {
      return processPageData
        .filter((item) => item.routerName !== this.$route.name)
        .map((item) => ({
          pic: item.mainBg,
          title: item.pageNameEn,
          subTitle: item.pageName,
          route: {
            name: item.routerName,
          },
        }));
    },
  },
};
</script>

<style
  scoped
  src="@/assets/scss/process.scss"
  lang="scss">
</style>
