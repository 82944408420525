import processBgPic1 from '../assets/img/process/Process_1.jpg';
import processBgPic2 from '../assets/img/process/Process_2.jpg';
import processBgPic3 from '../assets/img/process/Process_3.jpg';
import techniqueMedicinePic1 from '../assets/img/process/03_technique_process/medicine/1.png';
import techniqueMedicinePic2 from '../assets/img/process/03_technique_process/medicine/2.png';
import techniqueMedicinePic3 from '../assets/img/process/03_technique_process/medicine/3.png';
// import techniqueMedicinePic4 from '../assets/img/process/03_technique_process/medicine/4.png';
import techniqueMedicinePic5 from '../assets/img/process/03_technique_process/medicine/5.png';
import techniqueMedicinePic6 from '../assets/img/process/03_technique_process/medicine/6.png';
import techniqueMedicinePic7 from '../assets/img/process/03_technique_process/medicine/7.png';
import techniqueMedicinePic8 from '../assets/img/process/03_technique_process/medicine/8.png';
import techniqueMedicinePic9 from '../assets/img/process/03_technique_process/medicine/9.png';
import techniqueBeerPic1 from '../assets/img/process/03_technique_process/beer/1.png';
import techniqueBeerPic2 from '../assets/img/process/03_technique_process/beer/2.png';
import techniqueFlowPic1 from '../assets/img/process/03_technique_process/flow/1.png';
import techniqueFlowPic2 from '../assets/img/process/03_technique_process/flow/2.png';
import techniqueFlowPic3 from '../assets/img/process/03_technique_process/flow/3.png';
import techniqueFlowPic4 from '../assets/img/process/03_technique_process/flow/4.png';
import techniqueFlowPic5 from '../assets/img/process/03_technique_process/flow/5.png';
import techniqueFlowPic6 from '../assets/img/process/03_technique_process/flow/6.png';
import techniqueEnviroPic1 from '../assets/img/process/03_technique_process/enviro/1.png';
import techniqueEnviroPic2 from '../assets/img/process/03_technique_process/enviro/2.png';
import techniqueEnviroPic3 from '../assets/img/process/03_technique_process/enviro/3.png';
import techniqueEnviroPic4 from '../assets/img/process/03_technique_process/enviro/4.png';
import techniqueEnviroPic5 from '../assets/img/process/03_technique_process/enviro/5.png';
import techniqueEnviroPic6 from '../assets/img/process/03_technique_process/enviro/6.png';
import techniqueEnviroPic7 from '../assets/img/process/03_technique_process/enviro/7.png';
import techniqueEnviroPic8 from '../assets/img/process/03_technique_process/enviro/8.png';
import techniqueEnviroPic9 from '../assets/img/process/03_technique_process/enviro/9.png';
import techniqueEnviroPic10 from '../assets/img/process/03_technique_process/enviro/10.png';

export const processPageData = [
  {
    routerName: 'IntegrationProgramme',
    pageName: '整合方案',
    pageNameEn: 'Integration Programme',
    mainBg: processBgPic1,
    titleWord: [
      {
        word: 'Integration',
        ifBold: true,
      },
      {
        word: 'Programme',
        ifBold: false,
      },
    ],
    bottomBgColor: '#f8f8f8',
  },
  {
    routerName: 'KeyPoint',
    pageName: '成功關鍵',
    pageNameEn: 'KEY POINTS OF SUCCESS',
    mainBg: processBgPic2,
    titleWord: [
      {
        word: 'Key Points of',
        ifBold: false,
      },
      {
        word: 'Success',
        ifBold: true,
      },
    ],
    bottomBgColor: '#f8f8f8',
  },
  {
    routerName: 'TechniqueProcess',
    pageName: '技術流程',
    pageNameEn: 'Technique Process',
    mainBg: processBgPic3,
    titleWord: [
      {
        word: 'technique',
        ifBold: false,
      },
      {
        word: 'Process',
        ifBold: true,
      },
    ],
    bottomBgColor: '#f8f8f8',
  },
];

export const keypointProcess = [
  {
    name: 'consult',
    textTitle: '企劃·基本計畫·提案',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'draft',
    textTitle: '基本設計',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'detail',
    textTitle: '詳細設計',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'purchase',
    textTitle: '採購',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'Construct',
    textTitle: '建設',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'test',
    textTitle: '試運轉',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
  {
    name: 'complete',
    textTitle: '正式生產',
    textInfo: '我們將確認顧客的需求，製作並提出「報價規格書」，在品質及成本面獲得顧客的認可後，進而完成合約。',
  },
];

export const techniqueProcessData = [
  {
    name: '流程',
    data: [
      {
        title: '食品・醫藥品製造流程',
        subTitle: 'PROCESS',
        info: null,
        modules: [
          {
            type: 'pic',
            url: null,
            title: '秤重→混合→殺菌→檢查→包裝→運送→保管',
            info: `
              「秤重→混合→殺菌→檢查→包裝→運送→保管」可以說是食品、醫藥品製造的基本流程，每個工程的技術設計並不是相同的，以下說明食品、醫藥品製造的基本流程。
              <br><br>
              必須深度了解生產流程中的各種條件，再選擇機器與裝置。用技術能力完成自動化、省力化、效率化、合理化、衛生、品質保證、計量法、調配·攪拌、殺菌·滅菌、充填、包裝、印字·記錄、追蹤等等關鍵項目，以符合需求成為最高性價比。
            `,
          },
          {
            type: 'pic',
            url: techniqueFlowPic1,
            title: null,
            info: null,
          },
          {
            type: 'pic',
            url: techniqueFlowPic2,
            title: null,
            info: null,
          },
          {
            type: 'toggle',
            lists: [
              {
                name: '特點',
                info: '考量質量平衡的最佳設定及品質保證機制來進行總體設計，透過監視系統持續監視生產線的運行狀況。',
              },
            ],
          },
        ],
      },
      {
        title: '監視系統',
        subTitle: null,
        info: null,
        modules: [
          {
            type: 'pic',
            url: techniqueFlowPic3,
            title: null,
            info: null,
          },
        ],
      },
      {
        title: '發酵・培養',
        subTitle: 'FERMENTATION·CULTURE',
        info: '麒麟集團擁有發酵·培養技術，將這些技術運用於食品·醫藥領域，滿足顧客需求。酒類、發酵飲料、發酵食品、微生物管理、生物技術領域裡培育相關技術。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                info: '「發酵」是透過乳酸菌、酵母菌等微生物，使處於自然狀態的微生物活化，而「培養」則是通過滅菌處理或添加發酵菌的人工行為。發酵用於製造酒、味噌、醬油、納豆、起司、優格、醃漬物等等，關於設備方面，會使用發酵桶來控制菌的環境條件，以及生物反應器用來製造醫藥品。',
                url: techniqueFlowPic4,
              },
              {
                name: '特點',
                info: '在發酵·培養的製程中，為了讓特定的微生物容易生長，不需要的微生物(所謂的細菌)不易繁殖，而調整環境中的溫度、濕度、空氣、液體的成分，因此，只有特定的微生物進行食品加工，同時防止因腐敗而無法食用的狀況發生，以提升食品保存性。',
                url: null,
              },
            ],
          },
        ],
      },
      {
        title: '萃取',
        subTitle: 'EXTRACTION',
        info: '萃取工藝是製造不同食品、醫藥品的核心工程，萃取的方式有浸泡式和濾滴式。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                info: '萃取是液體溶劑與原料接觸，溶解分離可溶性成分的方法，發展出將大豆、芝麻等種子植物的油脂成分有效分離的裝置，近年用於咖啡、茶類的萃取分離、以及醫藥品或調味料中使用的微量成分萃取。',
                url: techniqueFlowPic5,
              },
              {
                name: '特點',
                info: null,
                list: [
                  {
                    title: '浸泡式/臥式萃取機',
                    text: `
                      設備概要：顧名思義，它是開發為捻揉用的機器，旋轉的槳葉將豆沙、味噌等均勻捻揉的機器，但它首次被某間茶飲料製造商作為茶的萃取機。
                      <br>
                      萃取方法：茶葉投入捏合機，用旋轉的槳葉將浮著的茶葉打散/浸漬並萃取。
                      <br>
                      特別事項：蒸氣夾套使萃取溫度更容易管理。
                    `,
                  },
                  {
                    title: '濾滴式',
                    text: `
                      設備概要：投入粉碎的咖啡豆，用上方的噴林裝置均勻噴灑熱水並加以萃取的設備。
                      <br>
                      特別事項：下方端板有不銹鋼絲網或是法蘭絨濾布，並準備攪拌機，使投入的咖啡更平順。
                    `,
                  },
                ],
                url: techniqueFlowPic6,
              },
            ],
          },
        ],
      },
      {
        title: '化妝品·盥洗用品',
        subTitle: 'COSMETICS·TOILETRIES',
        info: '盥洗用品是個人保養品、身體洗淨及儀容為目的的商品總稱。它的製造流程是與食品製造流程非常相似的。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                list: [
                  {
                    title: '1.原材料的檢查與管理',
                    text: '這是製造符合標準化妝品的一個重要過程。制定儲存期限的基準。',
                  },
                  {
                    title: '2.原材料的計量',
                    text: '液體、固體、粉末等等的材料在潔淨的環境中以0.01g的高精度計量，於無塵室中完成工作。',
                  },
                  {
                    title: '3.調配工程',
                    text: '原料有油溶性基劑、水溶性基劑，於調配桶混合，混合後的原料送至乳化機(均質機)，製作出化妝品的「內容物」。',
                  },
                  {
                    title: '4.品質檢查',
                    text: '檢查每批產品的「內容物」。',
                  },
                  {
                    title: '5.充填·包裝',
                    text: '於無塵室充填至容器並包裝。',
                  },
                  {
                    title: '6.出貨',
                    text: '檢查及出貨。',
                  },
                ],
              },
              {
                name: '特點',
                list: [
                  {
                    title: '理念',
                    text: `
                      1. 將生產中的人為疏失減到最少。
                      <br>
                      2. 避免汙染及防止品質下降。
                      <br>
                      3. 設計保證高品質的系統。(持續按照品質設計製作出相同品質的產品)
                    `,
                  },
                  {
                    title: '化妝品的生產、管理、儲存及出貨的方針',
                    text: '著重於產品品質，而不是整體工廠作業員的安全與環境保護，另外，不適用於研發活動與最終產品的輸送。',
                  },
                  {
                    title: '化妝品GMP的適用產品',
                    text: '化妝品、準藥品(不包括新指定、新範圍的準藥品)、口腔清新劑、殺蟲劑(不包括驅蟲劑)。',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '飲料',
    data: [
      {
        title: '精釀啤酒',
        subTitle: 'CRAFT BEER',
        info: null,
        modules: [
          {
            type: 'pic',
            url: techniqueBeerPic1,
            title: '自動化 -糖化工程-',
            info: `
              技術說明 導入從原料處理到酵母添加的自動化，可以登錄各種液種的配方，實現可自由切換設定的生產製程，根據設備規模以及作業效率，也可以部份使用手動方式，實現符合顧客期望的自動化工程。<br>
              另外，配備簡潔明瞭的輸入介面，客戶可以自行的登錄配方。
            `,
          },
          {
            type: 'pic',
            url: techniqueBeerPic2,
            title: '全自動化 -發酵工程-',
            info: `
              技術說明 發酵/儲藏桶溫度管理、壓力管理的全自動化，可以依照不同液種，設定發酵溫度、時間，創造省力及品質安定之工程，同樣也能實現BBT、酵母桶等等各種桶槽的自動化。<br>
              另外，配備簡潔明瞭的輸入介面，客戶可以自行的登錄配方。
            `,
          },
        ],
      },
    ],
  },
  {
    name: '生物藥、無菌製劑',
    data: [
      {
        title: '生物藥',
        subTitle: 'BIOPHARMACEUTICAL DRUG SUBSTANCE',
        info: '本公司以KIRIN的發酵技術為基礎，擁有以大腸菌及CHO細胞為宿主的「生物醫藥品建設」豐富實績。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                info: null,
                list: [
                  '以重組DNA/PEG技術生產“微生物/動物細胞”為宿主的抗體藥物及疫苗生產設備',
                  'SUB(一次性生物反應器)～大型培養設備(~12kl)',
                  '控制重要參數，以確保在各種培養條件下的培養過程',
                  '製造多樣化產品',
                  '高濃度或是大量蛋白質溶液的分離純化設備',
                  'Inline稀釋設備',
                  '在線清洗(CIP)/在線滅菌(SIP)的色譜系統',
                  '生菌試驗·病毒清除技術',
                  'CIP/SIP的最佳化設計',
                  '廢水滅活處理',
                  '用水品質管理技術(熱水殺菌、SIP)',
                  '情報安全，限制控制系統路徑',
                ],
                url: null,
              },
              {
                name: '特點',
                info: '本公司活用於集團內醫藥品工廠建設、營運、保全的實績與經驗，將培養設備建置技術擴展至集團以外的生物藥品製造工廠領域。',
                url: [
                  techniqueMedicinePic1,
                  techniqueMedicinePic2,
                ],
              },
            ],
          },
        ],
      },
      {
        title: '無菌製劑',
        subTitle: 'STERILE DRUG PRODUCT',
        info: null,
        modules: [
          {
            type: 'topPoints',
            list: [
              '「生物醫藥品」無菌注射劑',
              '製造多品項',
              '液劑、凍結乾燥製劑之生產設備',
            ],
          },
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                info: null,
                list: [
                  '無菌環境裝置「隔離裝置」or「RABS」的選擇',
                  '採用無菌環境裝置的分區',
                  '充填封蓋~鎖蓋及冷凍乾燥的最短運送距離',
                  '為了風險評估，決定實施環境監控',
                  '「Vial瓶、安瓿、預填充式注射器、蜂巢式包裝等等」依照劑型進行充填',
                  '採用拋棄式充填系統，降低洗淨負擔',
                  '於調液設備及充填設備的損失最小化',
                  '用水的品質管理技術(熱水殺菌、SIP)',
                ],
                url: techniqueMedicinePic3,
              },
              {
                name: '特點',
                info: '對於生物藥製品，品質影響較小的充填方式',
                url: techniqueMedicinePic5,
              },
            ],
          },
        ],
      },
      {
        title: '再生醫療',
        subTitle: 'REGENERATIVE MEDICINE',
        info: '擁有生物藥、無菌操作技術的豐富實績，活用這些經驗，建構再生醫療設備。',
      },
      {
        title: '驗證支援',
        subTitle: 'VALIDATION SUPPORT',
        info: '依據除錯與確認(C&Q)的思想，減少顧客驗證的負擔及建構風險基礎方法的文書體系，運用於最新的GMP稽查。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                info: null,
                list: [
                  '執行系統影響評估(SIA)，如系統直接影響製品品質則為GMP設備，其餘則為非GMP設備。',
                  '非GMP設備為GEP範疇，不屬於驗證對象，減輕顧客的負擔',
                  '以風險基礎方法執行風險評估，呈現於URS，再透過DQ/IQ/OQ驗證風險評估結果。',
                ],
                url: techniqueMedicinePic6,
              },
              {
                name: '特點',
                info: '由於Good Manufacturing Practice（GMP）的文書，是與Good Engineering Practice （GEP）密切相關的，本公司會協助製作文書資料，從驗證主要計畫 (VMP)，到性能驗證(PQ)的各式資料。',
                url: techniqueMedicinePic7,
              },
            ],
          },
        ],
      },
      {
        title: '定期修繕支援',
        subTitle: 'PERIODICAL REPAIR SUPPORT',
        info: '基於製藥公司集團的營運、維護工作中所獲得的知識，我們考量到檢查維護項目及週期的妥當性，同時進行紀錄，建立一個PDCA的維護系統。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
                info: null,
                list: [
                  '根據現狀調查，確認既有設備的文件(流程表、設備清單、閥門清單、儀器清單、驗證文件等等)，並規定設備、機器的差異或是檢查項目及零件更換項目。',
                  '以生物醫藥工廠的豐富實績為基礎，於必要時實施風險管理，決定各設備的檢查週期及零件更換週期。',
                  '毫無遺漏地檢查，以標準格式製作報告書摘要文件(易於追蹤檢查)',
                  '從報告書摘要文件評估各設備的零件更換週期，並於定期維修計畫反映平時的故障狀況。',
                ],
                url: techniqueMedicinePic8,
              },
              {
                name: '特點',
                info: '以預防維護為目的，從制定計畫到執行、準備文件，貢獻於品質保證及穩定營運。',
                url: techniqueMedicinePic9,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '環境',
    data: [
      {
        title: '食品工廠的二氧化碳減排',
        subTitle: 'CO₂ REDUCTION',
        info: '新建或改建食品工廠時，我們會分析食品工廠的製造過程，提出不同規模的節能措施，為全工廠的減碳排放做出貢獻。',
        modules: [
          {
            type: 'toggle',
            lists: [
              {
                name: '技術說明',
              },
            ],
          },
          {
            type: 'title',
            title: '熱能回收',
          },
          {
            type: 'pic',
            url: null,
            title: '液體升溫冷卻的熱回收',
            info: '在液體製品的加工中，為了殺菌等目的，配管內連續進行升溫、冷卻的製程，因冷卻而吸收熱源的介質為升溫側，並使熱能平衡，提高熱能回收效益。',
          },
          {
            type: 'pic',
            url: techniqueEnviroPic1,
            title: '現狀',
            info: null,
          },
          {
            type: 'pic',
            url: techniqueEnviroPic2,
            title: '熱能回收案(溫度維持時間較短)',
            info: null,
          },
          {
            type: 'pic',
            url: [
              techniqueEnviroPic3,
              techniqueEnviroPic4,
            ],
            title: '熱能回收案(溫度維持時間較長)',
            info: null,
          },
          {
            type: 'title',
            title: '廢氣回收熱交換器',
          },
          {
            type: 'pic',
            url: null,
            title: '鍋爐、油炸的廢熱回收',
            info: '通常鍋爐或油炸排出的熱，透過煙囪排出到大氣中，但是如果將熱回收再利用，是可以使用於溫水製造等等的預熱作業。',
          },
          {
            type: 'pic',
            url: null,
            title: '熱能回收(溫度維持時間較長)',
            info: '鍋爐、連續爐的廢氣回收熱交換器架設於煙囪，此系統會供給熱源給洗淨用的熱水。',
          },
          {
            type: 'pic',
            url: techniqueEnviroPic5,
            title: null,
            info: null,
          },
          {
            type: 'title',
            title: '污水再利用-節水',
          },
          {
            type: 'pic',
            url: null,
            title: null,
            info: `
              製程中或是製程後的廢水，通常是經過污水處理設備後排出，但是如果透過洗淨工程的分析，適當地處理排水點的污水並且再利用，在某些情況下，是可以透過改變水的使用順序來大幅節省水資源。
            `,
          },
          {
            type: 'pic',
            url: null,
            title: null,
            info: `
              將原料洗淨的污水，去除雜質後，100%循環再利用，將殺菌工程的殺菌劑濃度視為主要控制點，再加上安全性的確保，來減少水的用量。執行省水措施，不僅可以減少用水成本，還可以縮小用水相關設備的規模，例如污水設備，進而降低初期成本。            
            `,
          },
          {
            type: 'pic',
            url: techniqueEnviroPic6,
            title: null,
            info: null,
          },
          {
            type: 'pic',
            url: techniqueEnviroPic7,
            title: null,
            info: null,
          },
          {
            type: 'pic',
            url: techniqueEnviroPic8,
            title: '過去的技術',
            info: '此生產過程的污水，經過污水處理設備後排放。',
          },
          {
            type: 'pic',
            url: techniqueEnviroPic9,
            title: '節水改善技術',
            info: '將原料洗淨的污水，去除雜質後，100%循環再利用，將殺菌工程的殺菌劑濃度視為主要控制點，再加上安全性的確保，來減少水的用量。',
          },
          {
            type: 'list',
            list: [
              {
                title: '省水對策',
                text: `
                  1. 調查各作業區域的使用量，將設備配置集中，並保持地面乾燥。
                  <br>
                  2. 優化生產流程及生產方式，確認使用水的消耗，再思考如何減少使用量的合理措施。
                  <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;<span class="txt-theme">・</span>使用少量的水清洗乾淨
                  <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;→ 水的使用量、洗淨時間的修正
                  <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;<span class="txt-theme">・</span>廢水回收再利用
                  <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;→ 最終沖洗水作為第一次洗淨水(需要與CP、CCP管理連動)
                  <br>
                  3. 提升生產和出貨容器的洗淨效率
                  <br>
                  4. 改變「用大量的水清洗」=「乾淨」的觀念
                  <br>
                  5. 將清潔功能視為設備設置的條件之一
                `,
              },
            ],
          },
        ],
      },
      {
        title: '污水處理設備',
        subTitle: 'WASTEWATER TREATMENT FACILITY',
        info: '食品工廠於生產過程所排放的污水性質，會因為產品的種類、數量、清洗頻率而有所不同，我們會根據污水的性質分析，來掌握污水排放的特性，再全面評估各種污水處理方式，並提出最佳的污水處理設備。',
        modules: [
          {
            type: 'pic',
            url: techniqueEnviroPic10,
            title: '污水處理設備的比較',
            info: null,
          },
        ],
      },
    ],
  },
];
